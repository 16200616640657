import React from "react";
import moment from "moment";
import HttpCliente from "../../../services/HttpCliente";
import axios from "axios";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel.jsx";
import AddEditBankAccountPopup from "../../../components/popups/add-edit-bank-account";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import ModalGeneral, { ModalGeneralType } from "../../../components/modal/modalGeneral";

const NewGreyList = () => {
    let { kyc_id, edit_mode } = useParams();
    let path = window.location.pathname;

    const [document_type, set_document_type] = React.useState("");
    const [document_number, set_document_number] = React.useState("");
    const [account_number, set_account_number] = React.useState("");
    const [account_currency, set_account_currency] = React.useState("");
    const [reject_reason, set_reject_reason] = React.useState("");
    const [payment_id, set_payment_id] = React.useState("");
    const [payment_amount, set_payment_amount] = React.useState("");
    const [bankData, setBankData] = React.useState([]);
    const [payment_bank_code, set_payment_bank_code] = React.useState("");
    const [sweetAlertActive, setSweetAlertActive] = React.useState(false);
    const [sweetAlertMessage, setSweetAlertMessage] = React.useState("Default");
    const [borderOutlineColor, setborderOutlineColor] = React.useState("#d5dbe0");
    const [formComplete, setFormComplete] = React.useState(false);
    const [sweetAlertSuccess, setSweetAlertSuccess] = React.useState<ModalGeneralType>("info");
    const [sweetAlertSuccessBool, setSweetAlertSuccessBool] =
        React.useState(false);
    const [sweetAlertActiveVariant2, setSweetAlertActiveVariant2] =
        React.useState(false);
    const [sweetAlertMessage1Variant2, setSweetAlertMessage1Variant2] =
        React.useState("Temp");
    const [sweetAlertMessage2Variant2, setSweetAlertMessage2Variant2] =
        React.useState("Default");
    const [sweetAlertActiveVariant3, setSweetAlertActiveVariant3] =
        React.useState(false);

    ////////////////////////////////////////////////SWEET ALERTS TOGGLE HANDLER////////////////////////////////////////////////////////////////////////////////////////
    const toggleSweetAlert = (message: string, success: any, type: ModalGeneralType) => {
        setSweetAlertSuccessBool(success);
        setSweetAlertSuccess(type);
        setSweetAlertActive(!sweetAlertActive);
        setSweetAlertMessage(message);
    };

    const toggleSweetAlertVariant2 = (message: string, message2: string) => {
        setSweetAlertActiveVariant2(!sweetAlertActiveVariant2);
        setSweetAlertMessage1Variant2(message);
        setSweetAlertMessage2Variant2(message2);
    };

    const saveButtonHandler = () => {
        if (
            document_type === "" ||
            document_number === "" || document_number === null ||
            account_currency === "" || account_currency === null ||
            account_number === "" || account_number === null ||
            reject_reason === "" || reject_reason === null
        ) {
            toggleSweetAlert("Please complete all mandatory fields", false, "danger");
            setborderOutlineColor("red");
        } else if(document_type === "DNI" && document_number.length !== 8) {
            toggleSweetAlert("DNI must be 8 digits", false, "danger");
        } else if (document_type === "CE" && document_number.length !== 9) {
            toggleSweetAlert("CE must be 9 digits", false, "danger");
        } else if (document_type === "PAS" && document_number.length !== 20) {
            toggleSweetAlert("PAS must be 20 digits", false, "danger");          
        } else if (account_number.length < 13 || account_number.length > 20) {
            toggleSweetAlert("Account number must be between 13 and 20 digits", false, "danger");
        } else {
            saveButtonPostRequest(true);
        }
    };
    const saveButtonPostRequest = (showSweetAlert: any) => {
        let sigData;

        sigData = {
            document_type: document_type,
            document_number: document_number,
            account_currency: account_currency,
            account_number: account_number,
            rejection_reason: reject_reason,
            payment_id: payment_id === "" ? null : payment_id,
            payment_amount: payment_amount === "" ? null : payment_amount ,
            payment_bank_code: payment_bank_code === "" ? null : payment_bank_code,
        };

        axios
            .post(`/invalidBankAccount`, sigData)
            .then((response) => {
                // console.log(response);
                if (showSweetAlert === true) {
                    toggleSweetAlert("Success!", true, "success");
                    if (typeof kyc_id !== `undefined`) {
                        if (typeof window !== `undefined`) {
                            window.location.href = path;
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                if (showSweetAlert === true)
                    toggleSweetAlert(`Error: ${error}`, false, "danger");
                if (showSweetAlert === false) {
                    toggleSweetAlert(
                        `Could not add to grey list, please try again`,
                        false,
                        "danger"
                    );
                }
            });
    };

    React.useEffect(() => {
        HttpCliente.get(`/bank`)
      .then((response) => {
        setBankData(response.data);
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      }, []);

    const clearButtonHandler = () => {
        set_document_type("");
        set_document_number("");
        set_account_number("");
        set_account_currency("");
        set_reject_reason("");
        setborderOutlineColor("#d5dbe0");
    };

    let titlePage = "Add to Grey List";
    if (kyc_id !== undefined) {
        titlePage = "Edit Grey List";
        if (edit_mode === "view") titlePage = "View Grey List";
    }

    function handleCustomerIdNumberInput(value: any) {
        if (document_type === "DNI") {
            if (value.length <= 8) set_document_number(value);
        } else if (document_type === "CE") {
            if (value.length <= 9) set_document_number(value);
        } else if (document_type === "PAS") {
            if (value.length <= 20) set_document_number(value);
        }
    }

    function handleNumericInput(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        // Remover cualquier carácter que no sea número
        event.target.value = value.replace(/\D/g, '');
    }

    const handleAmountInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        
        // Expresión regular que permite solo números y un solo punto decimal
        const regex = /^\d*\.?\d*$/;
      
        if (regex.test(value) || value === "") {
          // Si el valor es válido, actualiza el estado o el valor del input
          event.target.value = value;
        } else {
          // Si el valor no es válido, evita el cambio
          event.preventDefault();
        }
      };

    return (
        <div id="animate">
            {sweetAlertActive ? (
                <ModalGeneral
                    isModalOpen={sweetAlertActive}
                    onlyModal={false}
                    title={""}
                    type={sweetAlertSuccess}
                    onConfirm={() => {
                        if (formComplete === true) {
                            if (typeof kyc_id !== `undefined`) {
                                if (typeof window !== `undefined`) {
                                    window.location.href = path;
                                }
                            } else if (typeof window !== `undefined`) {
                                window.location.href = `${process.env.REACT_APP_Sub_Domain}/customers/greyList/grey-list`;
                            }
                        }
                        setSweetAlertActive(false);
                    }}
                    onClose={() => {
                        setSweetAlertActive(false);
                    }}
                >
                    <div>
                        <h3>{sweetAlertMessage}</h3>
                    </div>
                </ModalGeneral>
            ) : null}
            <h1 className="page-header">{titlePage}</h1>
            <Panel>
                <PanelHeader noButton>Main Info</PanelHeader>
                <PanelBody>
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <div className="row">
                                <div className="col-md-5">
                                    <label className="form-label" htmlFor="InputIdNumber">
                                        <strong>ID Number</strong>
                                    </label>
                                    <br />
                                    <select
                                        style={{
                                            border: `solid 1px ${document_type === "" ? borderOutlineColor : "#d5dbe0"
                                                }`,
                                        }}
                                        className="form-select"
                                        name="account-name-status"
                                        id="InputDocumentType"
                                        disabled={edit_mode === "view" ? true : false}
                                        value={document_type}
                                        onChange={(e) => {
                                            set_document_type(e.target.value);
                                        }}
                                    >
                                        <option value="" disabled hidden>
                                            Select
                                        </option>
                                        <option>DNI</option>
                                        <option>CE</option>
                                        <option>PAS</option>
                                    </select>
                                </div>
                                <div className="col-md-7">
                                    <label className="form-label">&nbsp;</label>
                                    <br />
                                    <input
                                        style={{
                                            display: "inline",
                                            border: `solid 1px ${document_number === "" ? borderOutlineColor : "#d5dbe0"
                                                }`,
                                        }}
                                        type="text"
                                        className="form-control col-md-8"
                                        id="InputDocuementNumber"
                                        placeholder="Enter ID"
                                        disabled={edit_mode === "view" ? true : false}
                                        value={document_number}
                                        onInput={handleNumericInput}
                                        onChange={(e) => {
                                            handleCustomerIdNumberInput(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <label className="form-label" htmlFor="InputEmail">
                                <strong>Account Number</strong>
                            </label>
                            <input
                                type="text"
                                style={{
                                    border: `solid 1px ${(account_number === "" || account_number === null) ? borderOutlineColor : "#d5dbe0"
                                        }`,
                                }}
                                className="form-control"
                                id="InputAccountNumber"
                                placeholder="Enter Account Number"
                                disabled={edit_mode === "view" ? true : false}
                                value={account_number}
                                onInput={handleNumericInput}
                                onChange={(e) => {
                                    set_account_number(e.target.value);
                                }}
                            />
                        </div>
                        <div className="mb-3 col-md-3">
                            <label className="form-label">
                                <strong>Account Currency</strong>
                            </label>
                            <br />
                            <select
                                style={{
                                    display: "inline",
                                    border: `solid 1px ${document_number === "" ? borderOutlineColor : "#d5dbe0"
                                        }`,
                                }}
                                className="form-select"
                                name="currency-select"
                                id="InputAccountCurrency"
                                placeholder="Select Currency"
                                value={account_currency}
                                onChange={(e) => {
                                    set_account_currency(e.target.value);
                                }}
                            >
                                <option value="" disabled hidden style={{ color: "gray" }}>
                                    Select..
                                </option>
                                <option value={"PEN"} key={"pen"}>
                                    PEN
                                </option>
                                <option value={"USD"} key={"usd"}>
                                    USD
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <label className="form-label" htmlFor="InputEmail">
                                <strong>Payment ID</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="InputPaymentId"
                                placeholder="Enter Payment ID"
                                disabled={edit_mode === "view" ? true : false}
                                value={payment_id}
                                onInput={handleNumericInput}
                                onChange={(e) => {
                                    set_payment_id(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-3 mb-3">
                            <label className="form-label" htmlFor="InputEmail">
                                <strong>Payment Amount</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="InputPaymentAmount"
                                placeholder="Enter Payment Amount"
                                disabled={edit_mode === "view" ? true : false}
                                value={payment_amount}
                                onInput={handleAmountInput}
                                onChange={(e) => {
                                    set_payment_amount(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-3 mb-3">
                            <label className="form-label" htmlFor="InputBank">
                                <strong>Bank</strong>
                            </label>
                            <br />
                            <select
                                className="form-select"
                                name="bank"
                                id="InputBank"
                                value={payment_bank_code}
                                onChange={(e) => {
                                    set_payment_bank_code(e.target.value);
                                }}
                            >
                                <option value="" disabled hidden>
                                    Select Bank
                                </option>
                                {typeof bankData !== `undefined`
                                    ? bankData.map((value: any, index: any) => {
                                        return (
                                            <option value={value.bankCode} key={value.bankCode}>
                                                {value.bankName}
                                            </option>
                                        );
                                    })
                                    : null}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label className="form-label">
                                <strong>Rejection Reason</strong>
                            </label>
                            <textarea
                                style={{
                                    display: "inline",
                                    border: `solid 1px ${document_number === "" ? borderOutlineColor : "#d5dbe0"
                                        }`,
                                }}
                                disabled={edit_mode === "view" ? true : false}
                                className="form-control"
                                id="InputRejectReason"
                                value={reject_reason}
                                onChange={(e) => {
                                    set_reject_reason(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </PanelBody>
                <PanelBody>
                    <div
                        className="panel-button-row"
                        style={{ padding: "0px 5px 0px 0px", justifyContent: "flex-end" }}
                    >
                        <div style={{ paddingRight: "15px" }}>
                            <button
                                className="panel-iconbutton-search"
                                style={{ marginRight: "-5px" }}
                                onClick={() => {
                                    saveButtonHandler();
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="white"
                                    width="18px"
                                    height="18px"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                                </svg>
                                <strong>Save</strong>
                            </button>
                        </div>
                        <div>
                            <button
                                className="panel-iconbutton-clear"
                                onClick={() => {
                                    clearButtonHandler();
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="white"
                                    width="20px"
                                    height="20px"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z" />
                                </svg>
                                <strong>Clear</strong>
                            </button>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default NewGreyList;
